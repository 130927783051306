// The explorer manages the state of the currently selected tab
import React, { useState } from 'react';
import styles from './DashExplorer.module.css';

const DashExplorer = ({ onTabClick }) => {
  const [selectedTab, setSelectedTab] = useState('file upload'); // default page is file upload

  const tabList = [
    { name: 'file upload', icon: 'https://s1.piq.land/2016/11/29/ZjsyJYg4kKhn0wy7uTwuAo5a_400x400.png' },
    { name: 'spending', icon: 'https://s1.piq.land/2016/11/29/ZjsyJYg4kKhn0wy7uTwuAo5a_400x400.png' },
    { name: 'budgets', icon: 'https://s1.piq.land/2016/11/29/ZjsyJYg4kKhn0wy7uTwuAo5a_400x400.png' },
    { name: 'goals', icon: 'https://s1.piq.land/2016/11/29/ZjsyJYg4kKhn0wy7uTwuAo5a_400x400.png' },
    { name: 'investments', icon: 'https://s1.piq.land/2016/11/29/ZjsyJYg4kKhn0wy7uTwuAo5a_400x400.png' },
  ];

  const handleTabClick = (tab) => {
    setSelectedTab(tab.name.toLowerCase());
    onTabClick(tab.name.toLowerCase()); // Notify the parent component about the selected tab
  };

  return (
    <div className={styles.dashexplorer}>
      <div className={styles.dashexplorer_title}>explorer</div>
      <div className={styles.dashexplorer_tabs}>
        {tabList.map((tab) => (
          <div
            key={tab.name}
            className={`${styles.dashexplorer_tab} ${selectedTab === tab.name.toLowerCase() ? styles.selected_tab : ''}`}
            onClick={() => handleTabClick(tab)}
          >
            <img alt="" src={tab.icon} />
            <p>{tab.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export { DashExplorer };
