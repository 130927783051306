import React, { useState } from 'react';

import {Button} from '../../components/';
import { setLinearBackground } from '../../components/Gradient/setLinearBackground';
import { VerbRenderer } from '../../components/VerbTable/VerbRenderer';
import harry from '../../harry_wand.webp';

import styles from './Portfolio.module.css';

export function Portfolio() {
  const backgroundStyle = setLinearBackground(['#820AFF', '#F834F0', '#ED9E5B']);

  // Verbs to display in table
  const Aller = ['vais', 'vas', 'va', 'allons', 'allez', 'vont'];
  const Avoir = ['\'ai', 'as', 'a', 'avons', 'avez', 'ont'];
  const Être = ['suis', 'es', 'est', 'sommes', 'être', 'sont'];
  const Vouloir = ['veux', 'veux', 'veut', 'voulons', 'voulez', 'veulent'];
  const Pouvoir = ['peux', 'peux', 'peut', 'pouvons', 'pouvez', 'peuvent'];

  const [showHarry, setShowHarry] = useState(false);
  const handleButtonClick = () => {
    setShowHarry(!showHarry);
  };

  return (
    <div  className={styles.pagewrapper} style={backgroundStyle}>
      <div className={styles.textwrapper}>

        <Button onClick={handleButtonClick}>UR A WIZARD, HARRY</Button>
        {showHarry && <img src={harry} alt="where'd he go?" title="I'm a what?"
            style={{ height: '100px', width: 'auto', display: 'flex'}} />}

        <h2 style={{ fontWeight: 'normal' }}>Verb render test:</h2>
        <VerbRenderer verbs={{ Aller, Avoir, Être, Vouloir, Pouvoir}} />;

      </div>
    </div>
  );
}