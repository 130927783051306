// FileUpload component
// creates the user interface for file selection/upload and prevents duplicate files

import React, {useRef} from 'react';
import { Button } from '../../components/Button/Button';

const FileUpload = ({ onFilesUpload, existingFileNames, setSelectedFile }) => {
  const fileInputRef = useRef(null);

  const handleFilesChange = (event) => {
    const files = event.target.files;

    // Filter out files with duplicate names
    const uniqueFiles = Array.from(files).filter((file) =>
      !existingFileNames.includes(file.name)
    );

    onFilesUpload(uniqueFiles, setSelectedFile);
  };

  const handleCustomButtonClick = () => {
    // Trigger a click on the hidden file input when the custom button is clicked
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div>
      {/* Custom browse button */}
      <Button onClick={handleCustomButtonClick}>Browse...</Button>

      <span style={{ marginLeft: '10px' }}>
        {existingFileNames.length > 0
          ? `${existingFileNames.length} file${existingFileNames.length > 1 ? 's' : ''} selected.`
          : 'No files selected.'}
      </span>


      {/* Hidden default file input button that's triggered by the custom button */}
      <input
        type="file"
        ref={fileInputRef} /* Listen for user clicking the custom button */
        style={{ display: 'none' }}  /* Don't render */
        onChange={handleFilesChange}
        accept=".csv"
        multiple
      />
    </div>
  );
};

export { FileUpload };