/* This component renders a verb conjugation table given string lists */

import React from 'react';
import styles from './VerbRenderer.module.css';

const VerbRenderer = ({ verbs }) => {
  // grab the list variable's name (verb name) to be used as the header
  const columns = Object.keys(verbs).map(name => ({ name, data: verbs[name] }));

  const pronouns = ["Je", "Tu", "Il/Elle/On", "Nous", "Vous", "Ils/Elles"]

  return (
    <table className={styles.myTable}>

      <thead>
        <tr>
          <th></th>
          {columns.map((column, index) => (
            <th key={index} className={styles.columnHeader}>{column.name}</th>
          ))}
        </tr>
      </thead>

      <tbody>
        {pronouns.map((value, rowIndex) => (
          <tr key={rowIndex}>
            <td className={styles.indexColumn}>{value}</td>
            {columns.map((column, columnIndex) => (
              <td key={columnIndex} className={styles.dataCell}>{column.data[rowIndex]}</td>
            ))}
          </tr>
        ))}
      </tbody>

    </table>
  );
};

export { VerbRenderer }