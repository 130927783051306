// WEBSITE TITLE

import React from 'react';
import {MyLink} from '../../../MyLink/MyLink';
import style from './SiteTitle.module.css';

function SiteTitle(props) {
  return (
    <MyLink className={style.SiteTitle} to={props.to}>
      {props.children}
    </MyLink>
  );
}

export { SiteTitle };
