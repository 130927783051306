import React from 'react';
import {Card} from '../../components/';
import styles from './Projects.module.css';

export function Projects() {
  return (
    <div className={styles.cardspage}>
      <div className={styles.cards}>
        <Card
          Image="https://cdn.images.express.co.uk/img/dynamic/109/590x/10-facts-space-dogs-149965.jpg"
          alttext="puppy in space"
          title="Advent of Code 2023"
          subtitle="games, coding"
          path="https://github.com/gamerplant3/advent_of_code_2023"
        ></Card>

        <Card
          Image="https://vignette.wikia.nocookie.net/adventuretimewithfinnandjake/images/9/97/S1e25_Finn_with_five_fingers.png"
          alttext="finn the human"
          title="Use Paper.js for something"
          subtitle="visuals, art"
          path="http://paperjs.org/"
        ></Card>

        <Card
          Image="https://i.pinimg.com/originals/70/98/a2/7098a2056ecc857c4a08a5a21acdf60e.gif"
          alttext="moira gif"
          title="Music!"
          subtitle="last.fm"
          path="http://last.fm/user/gamerplant3"
        ></Card>

        <Card
          Image="http://media.cntraveler.com/photos/57212200afc531496352e35e/master/pass/golden-gate-bridge-GettyImages-166267310.jpg"
          alttext="san fran bridge"
          title="Da Breeej"
          subtitle="work, engineering"
        ></Card>

        <Card
          Image="https://www.sunshinecooperative.co.uk/wp-content/uploads/2018/09/cucumber.jpg"
          alttext="cucumber"
          title="Play some slither"
          subtitle="games, fun"
          path="http://slither.io"
        ></Card>

        <Card
          Image="https://66.media.tumblr.com/692ce0269fea8d89e8cd0e6a9114106f/tumblr_nipsyy8IIT1u3hcn0o1_500.png"
          alttext="tom felton daniel radcliffe"
          title="Da boys"
          subtitle="cuties, my heart"
        ></Card>
      </div>
    </div>
  );
}