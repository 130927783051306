import React, { useState } from 'react'; // need to use state management

import { Button } from '../../../../components/Button/Button';
import { TableRenderer } from '../../../../components/TableRenderer/TableRenderer';

import styles from '../../../../components/TableRenderer/TableRenderer.module.css';

const Transactions = ({ isVisible, csvFiles }) => {
  const [selectedFile, setSelectedFile] = useState(null); // currently selected file

  const handleNameClick = (file) => {
    setSelectedFile(file); // change which table to display when a tab is clicked
  };

  return(
    <div style={{ height: '100%', display: isVisible ? 'block' : 'none' }}>
      {/* Display the tabs for each uploaded file at the top */}
      {csvFiles.length > 0 && (
        <div>
          {csvFiles.map((file, index) => (
            <Button key={index} onClick={() => handleNameClick(file)}> {file.name} </Button>
          ))}
        </div>
      )}

      <div className={styles.scrollable_div}>
        {/* Display the table with CSV contents */}
        {selectedFile && <TableRenderer selectedFile={selectedFile}/>}
      </div>
    </div>
  )

}

export { Transactions };