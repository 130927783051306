// THE HAMBURGER MENU WITH CROSSING BARS AND A DROPDOWN (DISPLAYED ON CLICK)
import React, { useState, useEffect, useRef, useCallback } from 'react';
import hamstyle from './Hamburger.module.css';
import themestyle from './ThemeSwitch.module.css';
import classNames from 'classnames';

function Hamburger(props) {

  // HAMBURGER BUTTON
  const [open, setOpen] = useState(false); // boolean: is the hamburger's state open or closed?

  const dropdownRef = useRef(null); // listen for when the user clicks away (outside the dropdown)
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) { setOpen(false) }};

  useEffect(() => {
    document.addEventListener('click', handleClickOutside); // start listening at initial render
    return () => {
      document.removeEventListener('click', handleClickOutside); // stop listening if they click outside
    }}, []);

  const changeToCross = open ? hamstyle.change : null; // boolean: change horizontal bars to cross
  const showTheStuff = open ? hamstyle.show : null; // boolean: show the dropdown links

  // HANDLE THEME SWITCH
  const [darkMode, setDarkMode] = useState(false); // listening for darkMode == True
  const handleThemeToggle = () => { setDarkMode(!darkMode) }; // toggle darkMode on/off
  const applyTheme = useCallback(() => {
      const body = document.body;
      if (darkMode) { body.setAttribute('data-theme', 'dark') }
      else { body.removeAttribute('data-theme') }
  }, [darkMode]);
  // apply default theme on initial render "[]"
  useEffect(() => { applyTheme() }, [applyTheme]);
  // update theme whenever darkMode is turned on/off "[darkMode]"
  useEffect(() => { applyTheme() }, [darkMode, applyTheme]);

  return (
    <div className={classNames( hamstyle.thehamburger, hamstyle.dropdown, changeToCross,
        themestyle.app, { [themestyle.darkMode]: darkMode } )} >

      {/* HAMBURGER MENU BARS */}
      <button onClick={() => { setOpen(!open) }} className={hamstyle.dropdown} ref={dropdownRef}>
        <div className={classNames(hamstyle.bar1, hamstyle.dropbutton)}></div>
        <div className={classNames(hamstyle.bar2, hamstyle.dropbutton)}></div>
        <div className={classNames(hamstyle.bar3, hamstyle.dropbutton)}></div>
      </button>

      {/* DROPDOWN MENU */}
      <div className={classNames( hamstyle.dropdowncontent, showTheStuff )} >
        {props.children}

        {/* THEME SWITCH BUTTON */}
      <div className={classNames(themestyle.switchwrapper)}>
        <label className={classNames(themestyle.switch)} htmlFor="checkbox">
          <input type="checkbox" id="checkbox" onChange={handleThemeToggle} checked={darkMode} />
          <div className={classNames(themestyle.slider, themestyle.round)}></div>
        </label>
        <em>Dark mode</em>
      </div>

      </div>

    </div>
  );
}

export { Hamburger };