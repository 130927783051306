import React, { useState } from 'react'; // need to use state management

import { setLinearBackground } from '../../components/Gradient/setLinearBackground';
import { DashExplorer } from './components/Explorer/Explorer';

import { CSVFiles } from './components/Body/CSVFiles';
import { Transactions } from './components/Body/Transactions';
import { Budgets } from './components/Body/Budgets';
import { Goals } from './components/Body/Goals';
import { Investments } from './components/Body/Investments';

import styles from './Dashboard.module.css';

const Dashboard = () => {
  const [selectedTab, setSelectedTab] = useState('file upload'); // default tab
  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  // also manage CSV file state in dashboard to make it available in all child components
  const [csvFiles, setCsvFiles] = useState([]);
  const handleCsvFilesChange = (files) => {
    setCsvFiles(files);
  };

  const backgroundStyle = setLinearBackground(['#820AFF', '#F834F0', '#ED9E5B']);

  return (
    <div className={`${styles.pagewrapper} ${styles.dashboard}`} style={backgroundStyle}>
      <div className={styles.textwrapper}>

        <h1>Spending Dashboard</h1>

        <div className={styles.dashwrapper}>
          <DashExplorer onTabClick={handleTabClick} />

          <div className={styles.dashbody}>
            {selectedTab === 'file upload' && (
              <CSVFiles isVisible={true} onCSVFilesChange={handleCsvFilesChange} />
            )}
            {selectedTab === 'spending' && ( <Transactions isVisible={true} csvFiles={csvFiles}/>)}
            {selectedTab === 'budgets' && ( <Budgets isVisible={true} /> )}
            {selectedTab === 'goals' && ( <Goals isVisible={true} /> )}
            {selectedTab === 'investments' && ( <Investments isVisible={true} /> )}
          </div>
        </div>

      </div>
    </div>
  );
};

export { Dashboard };
