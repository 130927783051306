import React from 'react';

import { setLinearBackground } from '../../components/Gradient/setLinearBackground';
import { Explorer } from './components/Explorer/Explorer';
import react_icon from '../../react_logo.svg';
import python_icon from '../../python_logo.svg';

import styles from './SiteMap.module.css';

const SiteMap = () => {
  const backgroundStyle = setLinearBackground(['#820AFF', '#F834F0', '#ED9E5B']);

  // Files to display in explorer
  const files = [
    { name: 'app.jsx', path: '/', icon: react_icon },
    { name: 'about.html', path: '/Portfolio', icon: 'https://s1.piq.land/2016/11/29/ZjsyJYg4kKhn0wy7uTwuAo5a_400x400.png' },
    { name: 'projects.py', path: '/Projects', icon: python_icon },
  ];

  return (
    <div className={styles.pagewrapper} style={backgroundStyle}>

      <Explorer fileList={files} />

      <div className={styles.textwrapper}>
        <h1> page content. maybe render image of sitemap </h1>
        <h1> highlighting the page being hovered over currently? </h1>
      </div>

    </div>
  );
};

export { SiteMap };
