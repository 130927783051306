// handleFileUpload component
// processes (reads and parses) the uploaded CSV then updates the state in Dashboard

import Papa from 'papaparse';

const handleFileUpload = (files, setCsvFiles, setSelectedFile) => {
  const promises = files.map((file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const content = event.target.result;
        // Use PapaParse to read CSV content
        Papa.parse(content, {
          complete: (result) => {resolve({ name: file.name, content: result.data });},
          header: false,
        });
      };

      reader.readAsText(file);
    });
  });

  Promise.all(promises).then((fileData) => {
    // Filter out duplicates before adding files to state
    setCsvFiles((prevFiles) => {
      const uniqueFiles = fileData.filter((newFile) =>
        !prevFiles.some((existingFile) => existingFile.name === newFile.name)
      );

      return [...prevFiles, ...uniqueFiles];
    });
  });
};

export { handleFileUpload };
