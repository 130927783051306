// PAGE LINKS IN THE HEADER THAT ARE DROPDOWN MENUS (DISPLAYED ON HOVER)

import React from 'react';
import style from './DropdownLink.module.css';
import classNames from 'classnames';

function DropdownLink(props) {
  return (
    <div className={style.alignheaderdiv}>
      <div className={style.dropdown}>
        <button className={style.dropnavlink}>{props.title}</button>
        <div className={classNames(style.dropdowncontent, style.aligndroplink)}>
          {React.Children.map(props.children, child => (
            <div className={style.dropdownLink}>{child}</div>
          ))}
        </div>
      </div>
    </div>
  );
}

export { DropdownLink };