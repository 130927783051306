// Header component - likely repeated on every page

import React, {useEffect} from 'react';
import styles from './Header.module.css';
import {SiteTitle} from './components/SiteTitle/SiteTitle';
import {HeaderLink} from './components/HeaderLink/HeaderLink';
import {DropdownLink} from './components/DropdownLink/DropdownLink';
import {Hamburger} from './components/Hamburger/Hamburger';
import {MyLink} from '../../components/MyLink/MyLink';

export function Header() {
  useEffect(() => {
    // Get the header element
    const headerElement = document.querySelector(`.${styles.header}`);

    // Get the computed height of the header
    const headerHeight = window.getComputedStyle(headerElement).height;

    // Set the --header-height CSS variable
    document.documentElement.style.setProperty('--header-height', headerHeight);
  }, []);

  return (
    <div className={styles.header}>
      <Hamburger>
        <MyLink to="#">this is a link</MyLink>
        <MyLink to="#">bruv we're dropping down</MyLink>
        <MyLink to="#">∠(´•０•`)〴</MyLink>
      </Hamburger>

      <SiteTitle to="/">WENDY</SiteTitle>

      <HeaderLink to="/knowledge">KNOWLEDGE</HeaderLink>

      <DropdownLink title="QUICK REF.">
        <MyLink to="#">1. this goes places</MyLink>
        <MyLink to="/secretmap">2. map so does this</MyLink>
        <MyLink to="/dashboard">3. dash what is order, boyo?</MyLink>
      </DropdownLink>

      <HeaderLink to="/projects">PROJECTS</HeaderLink>

      <HeaderLink to="/portfolio">PORTFOLIO</HeaderLink>

      <DropdownLink title="DROP ME">
        <MyLink to="#">oh ooooh be my friend. there once was</MyLink>
        <MyLink to="#">a man named michael finnegan who</MyLink>
        <MyLink to="#">had some whiskers on his chin again</MyLink>
      </DropdownLink>
    </div>
  );
}