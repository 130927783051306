// Wrap links in "MyLink"

import React from 'react';
import { Link } from 'react-router-dom';

export function MyLink({ to, children, className }) {
  return (
    <Link className={className} to={to}>
      {children}
    </Link>
  );
}