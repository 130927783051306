// PAGE LINKS IN THE HEADER THAT ARE NOT DROP DOWNS

import React from 'react';
import {MyLink} from '../../../MyLink/MyLink';
import style from './HeaderLink.module.css';

function HeaderLink(props) {
  return (
    <MyLink className={style.HeaderLink} to={props.to}>
      {props.children}
    </MyLink>
  );
}

export { HeaderLink };