/* Helper function: Invokes the generation of a gradient color array
then creates the line style syntax to set the background color */

import { generateGradientColors } from './gradientGenerator';

export function setLinearBackground( three_colours ) {
  // Create gradient colors
  const gradientColors = generateGradientColors(three_colours[0], three_colours[1], three_colours[2]);  // Create a linear gradient string for the background

  // Create a linear gradient string for the background
  const linearGradient = `linear-gradient(to bottom, ${gradientColors.join(', ')})`;

  // Apply the gradient to the main page background
  const backgroundStyle = {
    backgroundImage: linearGradient,
  };

  return backgroundStyle;
}