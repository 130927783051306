import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import styles from './App.css';

// SITE WIDE COMPONENTS
import { Header, Footer } from './components';

// PAGES
import { Main, Portfolio, Projects, Knowledge, Dashboard, SiteMap } from './pages';

function App() {
  return (
    <Router>
      <div className={styles.sitecontainer}>

        <Header />

        <Routes>
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/knowledge" element={<Knowledge />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/secretmap" element={<SiteMap />} />
          {/* keep main at the bottom or else all links will go to main (first match) */}
          <Route path="/" element={<Main />} />
        </Routes>

        <Footer />

      </div>
    </Router>
  );
}

export default App;