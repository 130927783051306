import React, { useState, useEffect } from 'react'; // need to use state management

import { FileUpload } from '../../../../components/FileUpload/FileUpload';
import { handleFileUpload } from '../../../../components/FileUpload/handleFileUpload';

const CSVFiles = ({ isVisible, onCSVFilesChange }) => {
  const [csvFiles, setCsvFiles] = useState([]); // state of all CSV files

  // callback to notify the parent (Dashboard) about the updated csvFiles
  useEffect(() => { onCSVFilesChange(csvFiles) }, [csvFiles, onCSVFilesChange]);

  return (
    <div style={{ display: isVisible ? 'block' : 'none' }}>
      <h3>Upload your CSV Files(s):</h3>

      <FileUpload
        onFilesUpload={(files) => handleFileUpload(files, setCsvFiles)}
        existingFileNames={csvFiles.map((file) => file.name)} />

      {/* Display the list of selected files */}
      {csvFiles.length > 0 && (
        <div>
          <h3>Staged Files</h3>
          <table>
            <tbody>
              {csvFiles.map((file, index) => (
                <tr key={index}>
                  <td>{file.name}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export { CSVFiles };