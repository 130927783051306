// Given 3 hex colours, returns the 12 hex colours necessary to render a gradient

function generateGradientColors(hexColor1, hexColor2, hexColor3) {
  // Function to convert hex to RGB
  function hexToRgb(hex) {
    // Remove the hash, if present
    hex = hex.replace(/^#/, '');

    // Parse the hex values
    const bigint = parseInt(hex, 16);

    // Extract RGB components
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return [r, g, b];
  }

  // Function to convert RGB to hex
  function rgbToHex(r, g, b) {
    const componentToHex = (c) => {
      const hex = c.toString(16);
      return hex.length === 1 ? '0' + hex : hex;
    };

    return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;
  }

  // Convert input hex colors to RGB format
  const rgbColor1 = hexToRgb(hexColor1);
  const rgbColor2 = hexToRgb(hexColor2);
  const rgbColor3 = hexToRgb(hexColor3);

  // Calculate intermediate colors (RGB format)
  const intermediateColor1 = [
    Math.round(rgbColor1[0] + (rgbColor2[0] - rgbColor1[0]) * 1 / 6),
    Math.round(rgbColor1[1] + (rgbColor2[1] - rgbColor1[1]) * 1 / 6),
    Math.round(rgbColor1[2] + (rgbColor2[2] - rgbColor1[2]) * 1 / 6)
  ];

  const intermediateColor2 = [
    Math.round(rgbColor1[0] + (rgbColor2[0] - rgbColor1[0]) * 2 / 6),
    Math.round(rgbColor1[1] + (rgbColor2[1] - rgbColor1[1]) * 2 / 6),
    Math.round(rgbColor1[2] + (rgbColor2[2] - rgbColor1[2]) * 2 / 6)
  ];

  const intermediateColor3 = [
    Math.round(rgbColor1[0] + (rgbColor2[0] - rgbColor1[0]) * 3 / 6),
    Math.round(rgbColor1[1] + (rgbColor2[1] - rgbColor1[1]) * 3 / 6),
    Math.round(rgbColor1[2] + (rgbColor2[2] - rgbColor1[2]) * 3 / 6)
  ];

  const intermediateColor4 = [
    Math.round(rgbColor1[0] + (rgbColor2[0] - rgbColor1[0]) * 4 / 6),
    Math.round(rgbColor1[1] + (rgbColor2[1] - rgbColor1[1]) * 4 / 6),
    Math.round(rgbColor1[2] + (rgbColor2[2] - rgbColor1[2]) * 4 / 6)
  ];

  // Calculate intermediate colors for the second part (RGB format)
  const intermediateColor5 = [
    Math.round(rgbColor2[0] + (rgbColor3[0] - rgbColor2[0]) * 1 / 6),
    Math.round(rgbColor2[1] + (rgbColor3[1] - rgbColor2[1]) * 1 / 6),
    Math.round(rgbColor2[2] + (rgbColor3[2] - rgbColor2[2]) * 1 / 6)
  ];

  const intermediateColor6 = [
    Math.round(rgbColor2[0] + (rgbColor3[0] - rgbColor2[0]) * 2 / 6),
    Math.round(rgbColor2[1] + (rgbColor3[1] - rgbColor2[1]) * 2 / 6),
    Math.round(rgbColor2[2] + (rgbColor3[2] - rgbColor2[2]) * 2 / 6)
  ];

  const intermediateColor7 = [
    Math.round(rgbColor2[0] + (rgbColor3[0] - rgbColor2[0]) * 3 / 6),
    Math.round(rgbColor2[1] + (rgbColor3[1] - rgbColor2[1]) * 3 / 6),
    Math.round(rgbColor2[2] + (rgbColor3[2] - rgbColor2[2]) * 3 / 6)
  ];

  const intermediateColor8 = [
    Math.round(rgbColor2[0] + (rgbColor3[0] - rgbColor2[0]) * 4 / 6),
    Math.round(rgbColor2[1] + (rgbColor3[1] - rgbColor2[1]) * 4 / 6),
    Math.round(rgbColor2[2] + (rgbColor3[2] - rgbColor2[2]) * 4 / 6)
  ];

  // Generate hex color codes
  const hexColors = [
    hexColor1,
    rgbToHex(...intermediateColor1),
    rgbToHex(...intermediateColor2),
    rgbToHex(...intermediateColor3),
    rgbToHex(...intermediateColor4),
    hexColor2,
    rgbToHex(...intermediateColor5),
    rgbToHex(...intermediateColor6),
    rgbToHex(...intermediateColor7),
    rgbToHex(...intermediateColor8),
    hexColor3,
    hexColor3
  ];

  return hexColors;
}

export { generateGradientColors };
