// Button component

import React from 'react';
import style from './Button.module.css';

function Button({ label, children, onClick, colours }) {
  const labelMarkup = label ? <label>{label}</label> : null;

  // user can specify background colour of the button if they want
  const buttonStyle = {

    // default colors if not user specified. if given one colour, it will
    // be the background colour with a black border. If two colours given, it
    // it will be background first then border.
    backgroundColor: (colours && colours[0]) || '',
    borderColor: (colours && colours[1]) || '',
  };

  return (
    <>
      {labelMarkup}
      <button className={style.Button} style={buttonStyle} onClick={onClick}>
        {children}
      </button>
    </>
  );
}

export { Button };