// Create a table to preview the contents of the uploaded CSV files

import React from 'react';
import tablestyles from './TableRenderer.module.css';

const TableRenderer = ({ selectedFile }) => {

  // 1. create column headers for the CSV files
  const generateTableHeaders = (fileName) => {
    const defaultHeaders = ['Date', 'Vendor', 'Debit', 'Credit'];
    // Credit card files have an extra column with the cc number
    if (fileName.toLowerCase().startsWith('credit')) {
      return [...defaultHeaders, 'Card ID'];
    }
    if (fileName.toLowerCase().startsWith('chequing') || fileName.toLowerCase().startsWith('savings')) {
      return defaultHeaders;
    }
    return defaultHeaders;
  };

  // 2. replace line breaks with spaces (i.e. handle multi-line cells)
  const formatMultiLineCellValue = (value) => {
    return value.replace(/[\r\n]+/g, ' ');
  };

  // 3. display the CSV file contents in a table
  return (
    <div>
      <table className={tablestyles.table}>
        <thead>
          <tr>
            {generateTableHeaders(selectedFile.name).map((header, index) => (
              <th key={index}>
                {header}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {selectedFile.content.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {Object.values(row).map((cell, cellIndex) => (
                <td key={cellIndex} className={tablestyles.td}>
                  {generateTableHeaders(selectedFile.name)[cellIndex] === 'Vendor'
                    ? formatMultiLineCellValue(cell)
                    : cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export {TableRenderer};