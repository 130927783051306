// FOOTER COMPONENT (REPEATED ON EVERY PAGE, PROBABLY)

import React, {useEffect} from 'react';
import styles from './Footer.module.css';

function Footer() {
  useEffect(() => {
    // Get the footer element
    const footerElement = document.querySelector(`.${styles.footer}`);

    // Get the computed height of the footer
    const footerHeight = window.getComputedStyle(footerElement).height;

    // Set the --footer-height CSS variable
    document.documentElement.style.setProperty('--footer-height', footerHeight);
  }, []);

  return (
    <div className={styles.footer}>
      <p className={styles.footerText}>crafted with sleepy love in Toronto :)</p>
    </div>
  );
}

export { Footer };