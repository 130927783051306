/* Given a fileList object, renders the file explorer sidebar */

import React, { useState } from 'react';

import { MyLink } from '../../../../components/MyLink/MyLink';

import styles from './Explorer.module.css';

const Explorer = ({ fileList }) => {
    const [open, setOpen] = useState(true);

    const toggleOpen = () => {
      setOpen(!open);
    };

    return (
      <div className={styles.explorer}>
        <p className={styles.explorer_title}>Explorer</p>
        <div>

          <label className={styles.explorer_heading}>
              {/* If open, apply .rotated style to chevron, else display per css styles */}
              <span className={`${styles.explorer_chevron} ${open ? styles.rotated : ''}`}
                onClick={toggleOpen}>
              </span>
              Site Map
          </label>

          {/* If open, display file list per css styles, else display:none */}
          <div className={styles.explorer_files} style={{ display: open ? '' : "none" }}>
            {fileList.map((file) => ( <MyLink to={file.path} key={file.name}>
                <div className={styles.explorer_file}>
                  <img alt={file.name} src={file.icon} />
                  <p>{file.name}</p>
                </div>
              </MyLink> ))}
          </div>

        </div>
      </div>
    );
 };

export { Explorer }