// CARD COMPONENT (FOR PROJECTS)

import React from 'react';
import styles from './Card.module.css';

function Card({ Image, alttext, title, subtitle, path }) {
  return (
    <a href={path} target="_blank" rel="noopener noreferrer" className={styles.card}>
      <img src={Image} alt={alttext} className="img" />
      <div className={styles.cardlabel}>
        <h4>
          <b>{title}</b>
        </h4>
        <p>{subtitle}</p>
      </div>
    </a>
  );
}

export { Card };