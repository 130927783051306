import React from 'react';
import styles from './Main.module.css';
import ollie from '../../pup.gif';

export function Main() {
  return (
    <div className={styles.pagewrapper}>
      <div className={styles.textwrapper}>

        <div id="header" style={{ textAlign: 'center' }}>
          <p className={styles.subtitle}>bonjour</p>
          <img src={ollie}
               alt="pup"
               title="baby"
               style={{ display: 'block', margin: 'auto auto', height: '60vh'}}
          />
        </div>

        <p className={styles.subtitle}>Updated 13 December 2023 ToT</p>

      </div>
    </div>
  );
}